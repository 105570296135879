<template>
  <CRow>
    <CCol sm="12">

      <CCard>

        <CCardHeader>
          <CIcon name="cil-window"/>
          Bestehenden Kunden ändern
        </CCardHeader>

        <CCardBody>
          <ValidationObserver v-slot="{ invalid }">
            <CForm class="no-mb" @submit.prevent="updateKunde()">

              <KundenText v-model="kdnr" formLabel="Kundennummer" rules=""/>
              <KundenText v-model="name" formLabel="Name"/>
              <KundenText v-model="name2" formLabel="Name 2" rules=""/>
              <KundenText v-model="zusatz" formLabel="Zusatz" rules=""/>
              <KundenText v-model="strasse" formLabel="Strasse" rules=""/>
              <KundenText v-model="hausnr" formLabel="Hausnummer" rules=""/>
              <KundenText v-model="plz" formLabel="Postleitzahl" rules=""/>
              <KundenText v-model="ort" formLabel="Ort" rules=""/>
              <KundenText v-model="ansprechpartner" formLabel="Ansprechpartner" rules=""/>
              <KundenText v-model="telefon" formLabel="Telefon" rules=""/>
              <KundenTextArea v-model="bemerkung" formLabel="Bemerkung" rules=""/>

              <FormError v-if="formError" :errormessage="formError"/>

              <CRow class="mt-4">
                <CCol col="12" class="text-right">
                  <CButton color="primary" class="px-4" :disabled="invalid" type="submit">Kunde ändern</CButton>
                </CCol>
              </CRow>

            </CForm>
          </ValidationObserver>
        </CCardBody>

      </CCard>

    </CCol>
  </CRow>
</template>

<script>
import Vue from 'vue'
import FormError from '@/components/FormError'
import KundenText from '@/components/FormElements/Text'
import KundenTextArea from '@/components/FormElements/TextArea'

export default {
  name: 'MitarbeiterEdit',
  components: {
    FormError,
    KundenText,
    KundenTextArea
  },
  props: {
    uuid: [Number, String]
  },
  data () {
    return {
      kdnr: '',
      name: '',
      name2: '',
      zusatz: '',
      strasse: '',
      hausnr: '',
      plz: '',
      ort: '',
      ansprechpartner: '',
      telefon: '',
      bemerkung: '',
      formError: null
    }
  },
  mounted () {
    if (this.uuid) {
      Vue.axios.get('/kunden/get/' + this.uuid)
        .then((response) => {
          this.kdnr = response.data.kdnr
          this.name = response.data.name1
          this.name2 = response.data.name2
          this.zusatz = response.data.zusatz
          this.strasse = response.data.strasse
          this.hausnr = response.data.hausnr
          this.plz = response.data.plz
          this.ort = response.data.ort
          this.ansprechpartner = response.data.ansprechpartner
          this.telefon = response.data.telefon
          this.bemerkung = response.data.bemerkung
        })
    }
  },
  methods: {
    updateKunde () {
      Vue.axios.put('/kunden/put/' + this.uuid, {
        kdnr: this.kdnr,
        name: this.name,
        name2: this.name2,
        zusatz: this.zusatz,
        strasse: this.strasse,
        hausnr: this.hausnr,
        plz: this.plz,
        ort: this.ort,
        ansprechpartner: this.ansprechpartner,
        telefon: this.telefon,
        bemerkung: this.bemerkung
      }).then((response) => {
        this.$router.push({ path: '/kunden/uebersicht' })
        this.$snotify.success('Der Kunde wurde angepasst.', {
          position: 'rightTop',
          timeout: 4000
        })
      }).catch((error) => {
        this.formError = error.response.data
      })
    }
  }
}
</script>
